import Vue from "vue";

import { getOrderLastEvent } from "@launerlondon/shared";
import type { RetailOrder, EventType } from "@launerlondon/shared";

interface Component extends Vue {
	order: RetailOrder;
	status: EventType;
	mini: boolean;
}

function isRed(this: Component): boolean {
	const statuses: EventType[] = [
		"placed",
		"pending",
		"charged",
		"failed",
		"approved",
		"cancelled",
		"started",
		"shipped",
		"return_created",
		"refunded",
		"repair_started",
	];

	return statuses.indexOf(this.status) !== -1;
}

function isOrange(this: Component): boolean {
	const statuses: EventType[] = [
		"started",
		"shipped",
		"refunded",
		"repair_started",
	];

	return statuses.indexOf(this.status) !== -1;
}

function isGreen(this: Component): boolean {
	const statuses: EventType[] = ["shipped", "refunded"];

	return statuses.indexOf(this.status) !== -1;
}

export default Vue.component("retail-order-status", {
	computed: {
		status(this: Component): string {
			return getOrderLastEvent(this.order).type;
		},
	},
	methods: { isRed, isOrange, isGreen },
	props: ["order", "mini"],
	template: `
	<div :class='["retail-order-status", {"retail-order-status_mini": mini}]' :title=status>
		<div class=retail-order-status__item-container>
			<span :class='["retail-order-status__item",  {"retail-order-status__item_red": isRed()}]'>
				<i :class='["retail-order-status__item_red__icon", "retail-order-status__item_red__icon_" + status.toLowerCase()]'></i>
			</span>
		</div>
		<div class=retail-order-status__link></div>
		<div class=retail-order-status__item-container>
			<span :class='["retail-order-status__item",  {"retail-order-status__item_orange": isOrange()}]'></span>
		</div>
		<div class=retail-order-status__link></div>
		<div class=retail-order-status__item-container>
			<span :class='["retail-order-status__item",  {"retail-order-status__item_green": isGreen()}]'></span>
		</div>
	</div>`,
});
